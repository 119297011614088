body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.OutlinedContainer {
  border: 1px solid rgb(225, 225, 225);
  border-radius: 10px;
}

.form-container {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.form-container-fullwidth {
  width: 100%;
  margin: 0 auto;
}

.form-field {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

/* Container needed to position the overlay. Adjust the width as needed */
.container {
  position: relative;
  width: 100%;
}

.image-container {
  display: flex; /* Flex container to center child img */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%; /* Full width of the parent */
  aspect-ratio: 1; /* Square aspect ratio */
  overflow: hidden; /* Hide the overflowed parts of the image */
  border: 1px solid; /* Your existing border styles */
  border-color: inherit; /* Inherits color from the theme or specify */
}

.image-container-image {
  max-width: 100%; /* Maximum width is the container's width */
  max-height: 100%; /* Maximum height is the container's height */
  object-fit: contain; /* Maintain aspect ratio and fit within box */
}

.image-container:hover {
  cursor: pointer; /* Changes the cursor to indicate the item is interactive */
}

/* Make the image to responsive */
.image {
  width: 100%;
  height: auto;
}

/* The overlay effect (full height and width) - lays on top of the container and over the image */
.changeableImage-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 0.3s ease;
  border-width: 1px;
  border-style: inset;
  cursor: pointer;
  overflow: hidden;
}

/* When you mouse over the container, fade in the overlay icon*/
.container:hover .changeableImage-overlay {
  opacity: 1;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
  font-size: 100px;
  position: absolute;
  text-align: center;
}

/* When you move the mouse over the icon, change color */
.fa-user:hover {
  color: #eee;
}

@media (max-width: 600px) {
  .form-container {
    width: 90%;
  }
}

.dropdown {
  position: absolute;
  top: 50px;
  right: 16px;
  border: 6px solid black;
  padding: 0;
  text-align: left;
  border-radius: 0.12rem;
  z-index: 10;
  animation: assetFadeIn 0.33s ease-in-out forwards;
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
}

.dropdown.active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.dropdown > p {
  margin: 0;
  padding: 0.375rem 0.88rem;
  min-width: 140px;
}
/* .dropdown > p:hover {
} */

.dropdown-separator {
  width: 100%;
  height: 10px;
}

.asset-grid-item {
  width: 100%;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1;
}

.asset-grid-button {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

@keyframes assetFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.assetGridNotLoaded {
  opacity: 0;
}

.assetGridFadeIn {
  opacity: 0;
  animation: assetFadeIn 0.3s ease-in-out forwards;
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
}

.assetGridFadeIn:hover {
  transform: scale(1);
}
