.container {
  width: 100%;
}

.errorElement {
  width: 100%;
  border: 1px solid red;
  border-radius: 5px;
  padding: 4px;
}
