.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-top: 2px solid var(--color-divider);
}

.content {
  width: 100%;
  padding: 32px;
  max-width: 1000px;
}

.statusMessage {
  padding: 16px;
}

.mainImageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  /* max-width: 1000px; */
}

.mainImageContainer img {
  border-radius: 5px;
  max-width: 100%;
  max-height: 1000px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.secondaryContentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  animation: fadeIn 1s ease-out forwards; /* Adjust the duration and timing function as needed */
}

.metaContainer {
  padding-block: 32px;
  width: 80%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding-bottom: 100px;
}

.secondaryImagesContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
}
