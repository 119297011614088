.sidebarContainer {
  flex-shrink: 0;
  flex-basis: 320px;
  background-color: var(--color-background);
  border-right: 1px solid var(--color-divider);
}

.sidebarContent {
  height: 100%;
  padding: var(--padding-base-size);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-base-size);
}

H2 {
  color: var(--color-text-secondary);
}
