.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the top */
  gap: 20px;
}

.mainContainer {
  width: 100vw;
  height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
}

.collectionContainer {
  border-radius: 10px;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  gap: 18px;
}

.collectionMetaContainer {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 8px;
}

.descriptionField {
  padding-left: 16px;
}

.descriptionButton {
  width: 200px;
  height: 30px;
  border: 0;
  background-color: var(--color-background);
  color: var(--color-text-placeholder);
  text-align: left;
  padding-left: 16px;
}

.collectionImageContainer {
  width: 100vw;
  height: 100px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: scroll;
}

.item {
  position: relative;
  border: 0;
  width: 100px;
  height: 100px;
  position: relative;
  box-sizing: border-box; /* Ensures padding is included in the width */
  min-width: 100px; /* Set a minimum width for items */
  flex: 0 0 auto; /* Prevent items from shrinking */
  overflow: hidden;
  border-radius: 3px;
}

.placeholderItem {
  position: relative;
  border: 0;
  width: 100px;
  height: 100px;
  position: relative;
  background-color: var(--color-background-placeholder);
  box-sizing: border-box; /* Ensures padding is included in the width */
  min-width: 100px; /* Set a minimum width for items */
  flex: 0 0 auto;
  border-radius: 3px;
}

.item {
  img {
    z-index: 1;
  }
}

.itemControls {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  padding: 4px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.itemControls .itemControlButton {
  height: 40px;
  width: 40px;
  padding: 6px;
  border: 1px solid var(--color-outline);
  background-color: var(--color-background);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.itemControls:hover {
  opacity: 1;
}

.item:hover .itemControls {
  opacity: 1;
  pointer-events: auto; /* Allows the button to be clickable when visible */
}

.itemContent {
  object-fit: cover;
  cursor: pointer;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.instructions {
  color: var(--color-text-placeholder);
  padding-left: 32px;
}
