.container {
  height: 100%;
  padding: 16px;
}

.loadingIndicator {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.assetGridContainer {
  height: 100%;
  display: grid;
  overflow-x: auto; /* Ensure horizontal scrolling */
  white-space: nowrap;
  grid-gap: 10px; /* Adjust the gap between items */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: auto;
}

.item {
  border: 0;
  width: 100%;
  padding-top: 100%; /* This makes the item a square */
  position: relative;
  transition: transform 0.3s ease;
}

/* To make content inside .item centered */
.itemContent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: transform 0.1s ease;
}

.itemContentSelected {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: scale(0.7);
  transition: transform 0.1s ease;
}

.item:hover {
  transform: scale(1.01);
}

.item.active {
  transform: scale(1);
}
