.App {
  text-align: center;
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: red;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 20px;
  font-weight: bold;
  margin: 0em;
}

/* Heading 2 */
h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0em;
}

/* Heading 3 */
h3 {
  font-size: 16px;
  font-weight: normal;
  margin: 0em;
}

/* Heading 4 */
h4 {
  font-size: 16px;
  font-weight: normal;
  margin: 0em;
}

/* Heading 5 */
h5 {
  font-size: 14px;
  font-weight: bold;
  margin: 0em;
}

/* Heading 6 - smallest, like a caption */
h6 {
  font-size: 13px;
  font-weight: bold;
  margin: 0em;
}

.text-field-minimal-large {
  border: 0px; /* Blue border */
  border-radius: 4px; /* Rounded corners */
  border: 1px solid var(--color-background-highlighted); /* Blue border */
  background: var(--color-background);
  padding: 10px 15px; /* Padding inside the text field */
  font-size: 18px; /* Text size */
  font-weight: bold;
  outline: none; /* Removes the default focus outline */
  transition: border-color var(--animation-base-timing) ease-in-out;
  width: 100%;
}

.text-field-minimal-large:focus {
  border: 1px solid var(--color-outline-highlighted);
}

.text-field-minimal {
  color: var(--color-text-secondary);
  border: 0px;
  border-radius: 4px;
  border: 1px solid var(--color-background-highlighted);
  background: var(--color-background);
  padding: 10px 0px;
  font-size: 16px;
  font-weight: normal;
  outline: none;
  transition: border-color var(--animation-base-timing) ease-in-out;
  width: 100%;
}

.text-field-minimal:focus {
  /* border: 1px solid var(--color-outline-highlighted); */
}

.text-area-minimal {
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: var(--color-text-secondary);
  width: 100%;
  border-radius: 4px;
  padding: 16px 0; /* Adds some padding inside the textarea for the text */
  border: 1px solid var(--color-background-highlighted);
  background: var(--color-background);
  outline: none;
  font-size: 16px;
  font-weight: normal;
  resize: vertical;
}

.text-area-minimal:focus {
  border: 1px solid var(--color-outline-highlighted);
}

input::placeholder,
textarea::placeholder {
  color: var(--color-text-placeholder);
}

/* Paragraph */
p {
  font-size: 16px; /* Comfortable reading size */
  line-height: 1.6; /* Adjust line spacing for readability */
}

.button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-outline);
  background-color: var(--color-background);
  border-radius: 4px;
  text-transform: uppercase;
  padding-inline: 16px;
  cursor: pointer;
}
.button:hover {
  border: 1px solid var(--color-interactive);
}

.button:active {
  border: 1px solid var(--color-outline);
  background-color: var(--color-background);
}

.buttonCTA {
  height: 40px;
  border: 1px solid var(--color-interactive);
  background-color: var(--color-background);
  border-radius: 4px;
  text-transform: uppercase;
  padding-inline: 16px;
}

.buttonCTA:hover {
  background-color: var(--color-interactive);
  color: white;
}

.buttonCTA:active {
  color: var(--color-text-primary);
  border: 1px solid var(--color-interactive);
  background-color: var(--color-background);
}

.toolbarButton {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-outline);
  background-color: var(--color-background);
  border-radius: 4px;
  text-transform: uppercase;
  padding-inline: 16px;
  cursor: pointer;
}

.toolbarButton:hover {
  border: 1px solid var(--color-interactive);
}

.toolbarIconButton {
  height: 44px;
  width: 44px;
  padding: 6px;
  border: 1px solid var(--color-outline);
  background-color: var(--color-background);
  border-radius: 22px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.toolbarIconButton img {
  height: 90%;
  width: 90%;
}

.toolbarIconButton:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
  /* border: 1px solid var(--color-outline-highlighted); */
}

.toolbarIconButton:active {
  box-shadow: none;
}

.toolbarButtonCTA {
  height: 40px;
  border: 1px solid var(--color-interactive);
  background-color: var(--color-background);
  border-radius: 4px;
  text-transform: uppercase;
  padding-inline: 16px;
}

.toolbarButtonCTA:hover {
  background-color: var(--color-interactive);
  color: white;
}

:root {
  --color-interactive: #000000;
  --color-background: #ffffff;
  --color-background-complimentary: #f4f4f4;
  --color-background-placeholder: #f1f1f1;
  --color-background-highlighted: #fafafa;
  --color-background-contrast: #000000;
  --color-brand-primary: #000000;
  --color-brand-secondary: #b90042;
  --color-divider: #e8e8e8;
  --color-outline: #f6f6f6;
  --color-outline-highlighted: #000000;

  --color-text-primary: #000000;
  --color-text-secondary: #5f5f5f;
  --color-text-disabled: #b3b3b3;
  --color-text-placeholder: #b3b3b3;

  --font-size-h1: "1rem";
  --font-weight-h1: 600;

  --font-size-h2: "3rem";
  --font-weight-h2: 200;

  --font-size-h3: "1.125rem";
  --font-weight-h3: 400;

  --font-size-subtitle: "1rem";
  --font-weight-subtitle: 500;

  --font-size-subtitle2: "0.875rem";
  --font-weight-subtitle2: 500;

  --font-size-body1: "1rem";
  --font-weight-body1: 400;

  --font-size-body2: "0.9rem";
  --font-weight-body2: 300;

  --spacing-base-size: 16px;
  --padding-base-size: 16px;

  --animation-base-timing: 0.36s;
}
