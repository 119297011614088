.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 802px;
  padding: 16px;
}

.accessoryImagesContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  /* grid-template-columns: repeat(12, 1fr); 12-column layout */
  /* gap: 10px; Adjust the gap as needed */
  /* background-color: var(--color-background); */
}

.accessoryImagesContainerDragging {
  display: flex;
  flex-direction: row;
  gap: 8px;

  /* grid-template-columns: repeat(12, 1fr); 12-column layout */
  /* gap: 10px; Adjust the gap as needed */
  /* background-color: var(--color-background); */
  height: 200px;
}

.imageCollectionContainer {
  padding: 16px;
}

.mainImageContainer {
  background-color: var(--color-background-placeholder);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 600px;
  border-radius: 5px;
}

.mainImageContainerLoading {
  position: relative;
  background-color: var(--color-background-placeholder);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 600px;
  border-radius: 5px;
}

.mainAssetProcessingMessageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);

  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 150px;
    padding: 16px 0;
    color: var(--color-text-secondary);
    background-color: white;
    border-radius: 10px;
    backdrop-filter: blur(10px);
  }
}

.mainAssetPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  x h4 {
    color: var(--color-text-placeholder);
  }
  img {
    width: 30px;
  }
}

.addExtraImagesTooltip {
  color: var(--color-text-secondary);
  padding: 0px 0;
}

.subAssetPlaceholderClickable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 114px;
  min-width: 114px;
  background-color: var(--color-background-placeholder);
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 5px;
}

.subAssetPlaceholderClickable:hover {
  transform: scale(1.02);
  border-radius: 5px;
}

.subAssetPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 114px;
  flex-grow: 1;
  background-color: var(--color-background-placeholder);
  opacity: 0.4;
  border-radius: 5px;
}

.subAssetPlaceholderProcessingIcon {
  width: 20px;
  height: 20px;
  animation: rotate 0.5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.containerDragging {
  background-color: var(--color-background-highlighted);
}

.item {
  max-width: 114px;
  flex-grow: 1;
  z-index: 1;
  transition: all 0.2s ease;
  border-radius: 7px;
}

.item img {
  border-radius: 3px;
}

.gridItem {
  border: 1px solid var(--color-outline);
  grid-column: span 6; /* Full width on extra-small devices */
  /* transform: scale(1.02); */
  /* transition: all 0.2s ease-in-out; */
}

.item:hover {
  transform: scale(1.02);
  z-index: 100;
}

.singleAssetContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  object-fit: contain;
  border-radius: 5px;
}
.imageClip {
  overflow: hidden;
}

.singleAssetError {
  height: 114px;
  text-align: center;
  background-color: var(--color-background-placeholder);
  border-radius: 3px;
}

.singleAssetToolbar {
  display: flex;
  flex-direction: row;
  gap: 16px;
  position: absolute;
  height: 58px;
  top: 30px;
  left: 50%;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 36px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none; /* Prevents the button from being clickable when not visible */
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.singleAssetToolbar .toolbarIconButton {
  height: 40px;
  width: 40px;
  padding: 6px;
  border: 1px solid var(--color-outline);
  background-color: var(--color-background);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.singleAssetToolbar .singleAssetToolbar .toolbarIconButton img {
  height: 20px;
  width: 20px;
}

.singleAssetToolbar .toolbarIconButton:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
  /* border: 1px solid var(--color-outline-highlighted); */
}

.singleAssetToolbar .toolbarIconButton:active {
  box-shadow: none;
  transform: scale(1);
}

.singleAssetContainer:hover {
  z-index: 1000;
}
.singleAssetContainer:hover .singleAssetToolbar {
  opacity: 1;
  pointer-events: auto; /* Allows the button to be clickable when visible */
}
