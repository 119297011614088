.container {
  background-color: var(--color-background);
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.01);
  padding: 32px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
}

.title {
  color: var(--color-text-secondary);
}

.body {
  color: var(--color-text-primary);
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  padding-top: 16px;
}

.buttonFlexGrow {
  flex-grow: 1;
}
