.toolbar {
  width: 100%;
  height: 60px;
  background-color: var(--color-background);
  padding: var(--padding-base-size);
  display: flex;
  flex-direction: row;
  gap: var(--spacing-base-size);
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-divider);
}

.contentGroup {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-base-size);
  justify-content: space-between;
  align-items: center;
}

button {
  cursor: pointer;
}

.spacer {
  display: hidden;
  width: var(--spacing-base-size);
  height: 100%;
}
