.container {
  width: 200px;
  height: 200px;
}

.contentNoImage {
  width: 100%;
  height: 100%;
}

.imageImportContainer {
  display: flex;
  justify-content: center;
  width: 600px;
  height: 500px;
}

.assetImage {
  display: block; /* Ensure the image is treated as a block-level element */
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.contentImage {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  object-fit: contain;
}

.buttonAddImage {
  width: 100%;
  height: 100%;
  background-color: var(--color-background);
  color: var(--color-brand-primary);
  border: 1px solid var(--color-outline);
  border-radius: 5px;
  font-size: var(--font-size-h2);
  transition: all 0.3s ease;
}
