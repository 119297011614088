.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 2px solid var(--color-divider);
}

.statusMessage {
  padding: 16px;
}

.loadingMessageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.innerContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.description {
  width: 100%;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background-color: --var(--color-background);
}

.mainContent {
  width: 100%;
  height: 100%;
}

.toolbar {
  width: 100%;
  height: 60px;
  background-color: red;
  padding: var(--padding-base-size);
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: space-between;
  align-items: center;
}

.buttonBarItem {
  width: 40px;
  height: 40px;
  background-color: blue;
}

.activityBar {
  height: 60px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  transition: all 0.2s ease-out;
  align-items: center;

  .activityIndicator {
    transform: translateY(-1px);
    color: var(--color-text-placeholder);
  }

  h6 {
    transition: all 0.2s ease-out;
    color: var(--color-text-secondary);
  }
}
